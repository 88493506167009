.opensource-main {
  width: 100%;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 400px;
}

.chart-wrapper {
  width: 45%;
  max-height: 350px;
}
